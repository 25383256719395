<template>
    <b-modal id="list-dialog"
             :size="size" :hide-header="!title"
             no-close-on-esc
             no-close-on-backdrop
             v-on:hidden="$emit('hidden')">

        <template #modal-title v-if="title">
            <font-awesome-icon :icon="icon" size="sm" v-if="icon" class="mr-2"/>
            {{ title }}
        </template>

        <div class="m-n3">
            <Table :api="this.api" with-filter autofocus class="m-0"/>
        </div>

        <template #modal-footer="{ ok }">
            <b-button pill variant="dark" class="pl-5 pr-5"
                      v-on:click="ok()">
                {{ $i18n.t('translations.Close') }}
            </b-button>
        </template>

    </b-modal>
</template>

<script>
import Table from "@/components/Table";

export default {
    name: "ListDialog",
    components: {
        Table,
    },
    props: {
        size: {type: String, default: 'xl'},
        icon: {type: Array, default: null},
        title: {type: String, default: null},
        api: {type: String, default: null},
    },
    mounted() {
        this.$bvModal.show('list-dialog');
    },
    methods: {
    }
}
</script>

<style scoped>
</style>
