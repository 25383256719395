<template>
    <tr>
        <template v-for="(field,fieldIndex) in fields">
            <template v-if="field.showintable">
                <template v-if="field.type === 'ID'">
                    <td :key="'td-' + fieldIndex + '-' + data.id" class="pt-1 pl-2"
                        :title="fieldTitle(field, data)">
                        <input type="checkbox" :id="field.name+'-'+data.id" v-model="data.selected"
                               v-on:change="$emit('select-row', $event)"/>
                    </td>
                </template>
                <template v-else>
                    <td :key="'td-' + fieldIndex + '-' + data.id"
                        :class="field.tdclassfield ? data[field.tdclassfield] : field.tdclass"
                        :style="field.tdstylefield ? data[field.tdstylefield] : field.tdstyle"
                        :title="fieldTitle(field, data)">
                        <Field :field="field" :data="data" :state="state"
                               v-on:inline-edit="$emit('inline-edit', $event)"/>
                    </td>
                </template>
            </template>
        </template>
        <td style="text-align: center;" :style="actionsColumnStyle">
            <template v-for="(action,actionIndex) in actions">
                <template v-if="!action.hidefield || !data[action.hidefield]">
                    <ButtonAction :key="'action-' + '-' + actionIndex + '-' + data.id" forward-events
                                  :action="action" :disabled="actionDisabled(action)"
                                  v-on:forward-event="handleForwardedEvent"
                                  v-if="action.type === 'BUTTON' && action.placement === 'TABLEROW'"/>
                    <RouteAction :key="'action-' + '-' + actionIndex + '-' + data.id" :action="action" :disabled="actionDisabled(action)" :data="data"
                                 v-if="action.type === 'ROUTE' && action.placement === 'TABLEROW'"/>
                </template>
            </template>
        </td>
    </tr>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction";
import Field from "@/components/fields/Field";
import RouteAction from "@/components/actions/RouteAction";
import FieldTitle from "@/mixins/FieldTitle";

export default {
    name: "CrudTableRow",
    components: {
        ButtonAction,
        Field,
        RouteAction,
    },
    props: ["data", "fields", "actions", "state"],
    mixins: [FieldTitle],
    computed: {
        actionsColumnStyle() {
            const actions = this.actions.filter((a) => a.placement === 'TABLEROW' && (!a.hidefield || !this.data[a.hidefield])).length;
            const width = 20 + actions * 29;
            return 'width:' + width + 'px';
        },
    },
    methods: {
        actionDisabled(action) {
            return (!!action.disabled || (action.disablefield && this.data[action.disablefield]));
        },
        handleForwardedEvent(eventData) {
            this.$emit(eventData.event, eventData.data);
        },
    }
}
</script>

<style scoped>
.btn.disabled, .btn:disabled {
    opacity: 0.15;
}
</style>
