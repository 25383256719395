<template>
    <div>
        <template v-if="field.type === 'BOOL'">
            <b-input-group :key="'field-'+field.id" :append="label" :class="field.inputgroupclass" :style="field.inputgroupstyle" size="sm">
                <b-input-group-prepend is-text>
                    <BoolField class="mr-n2 mb-n1" :field="field" :data="data" :state="state" v-on:input="$emit('input')"/>
                </b-input-group-prepend>
            </b-input-group>
        </template>
        <template v-else-if="field.type === 'DATALIST' || field.type === 'RELATION'">
            <div class="input-group input-group-sm" :class="field.inputgroupclass" :style="field.inputgroupstyle">
                <div :key="'field-label-'+field.id" class="input-group-prepend" v-if="label">
                    <span class="input-group-text">{{ label }}</span>
                </div>
                <SelectField :key="'field-'+field.id" style="flex-grow: 1;" :field="field" :data="data" :state="state"
                             v-on:input="$emit('input')"/>
                <div class="input-group-append" v-if="!readonly && (field.createrelation || field.updaterelation)">
                    <b-button :key="'create-relation-' + field.id" :title="$i18n.t('translations.Create')"
                              variant="success" squared
                              v-on:click="$emit('create-relation',field)"
                              v-if="field.createrelation">
                        <font-awesome-icon :icon="['fas', 'plus-circle']"/>
                    </b-button>
                    <b-button :key="'update-relation-' + field.id" :title="$i18n.t('translations.Edit')"
                              variant="warning" squared :disabled="disableUpdateRelationButton"
                              v-on:click="$emit('update-relation',field)"
                              v-if="field.updaterelation">
                        <font-awesome-icon :icon="['fas', 'pen']"/>
                    </b-button>
                </div>
            </div>
        </template>
        <template v-else-if="field.type === 'DATE'">
            <b-input-group :key="'field-'+field.id" :prepend="label" :class="field.inputgroupclass" :style="field.inputgroupstyle" size="sm">
                <b-form-datepicker size="sm" v-model="data[field.name]"
                                   :class="field.class" :style="field.style" :disabled="state.disabled"
                                   :placeholder="field.placeholder ? field.placeholder : $i18n.t('translations.Date')"
                                   :state="field.statefield ? data[field.statefield] : field.state"
                                   :date-format-options="{year: 'numeric', month: '2-digit', day: '2-digit'}"
                                   :start-weekday="field.startweekday ? field.startweekday : 1"
                                   :initial-date="field.initialdate ? field.initialdate : (field.initialdatefield ? data[field.initialdatefield] : null)"
                                   today-button reset-button close-button boundary="window" locale="be"
                                   v-on:input="$emit('input')"/>
            </b-input-group>
        </template>
        <template v-else-if="field.type === 'DATETIME'">
            <b-input-group :key="'field-'+field.id" :prepend="label" :class="field.inputgroupclass" :style="field.inputgroupstyle" size="sm">
                <b-form-input :class="field.class" :style="field.style" type="datetime" :disabled="state.disabled" v-model="data[field.name]"
                              v-on:input="$emit('input')"/>

            </b-input-group>
        </template>
        <template v-else-if="field.type === 'ENUM'">
            <b-input-group :key="'field-'+field.id" :prepend="label" :class="field.inputgroupclass" :style="field.inputgroupstyle" size="sm">
                <EnumField :field="field" :data="data" :state="state" v-on:input="$emit('input')"/>
            </b-input-group>
        </template>
        <template v-else-if="field.type === 'EMAIL'">
            <b-input-group :key="'field-'+field.id" :prepend="label" :class="field.inputgroupclass" :style="field.inputgroupstyle" size="sm">
                <b-form-input :class="field.class" :style="field.style" type="email" :disabled="state.disabled" v-model="data[field.name]"
                              v-on:input="$emit('input')"/>
            </b-input-group>
        </template>
        <template v-else-if="field.type === 'INPUTTYPE' || field.type === 'NUMERIC'">
            <b-input-group :key="'field-'+field.id" :prepend="label" :class="field.inputgroupclass" :style="field.inputgroupstyle" size="sm">
                <NumericField :field="field" :data="data" :state="state" v-on:input="$emit('input')"/>
                <b-input-group-append v-if="field.append">
                    <b-input-group-text>{{ field.append }}</b-input-group-text>
                </b-input-group-append>
            </b-input-group>
        </template>
        <template v-else-if="field.type === 'JSON'">
            <b-input-group :key="'field-'+field.id" :prepend="label" :class="field.inputgroupclass" :style="field.inputgroupstyle" size="sm">
                <b-form-textarea :class="field.class" :style="field.style" :rows="field.rows" :max-rows="field.maxrows" v-model="data[field.name]" :disabled="state.disabled"
                                 v-on:input="$emit('input')"/>
            </b-input-group>
        </template>
        <template v-else-if="field.type === 'PASSWORD'">
            <b-input-group :key="'field-'+field.id" :prepend="label" :class="field.inputgroupclass" :style="field.inputgroupstyle" size="sm">
                <b-form-input :class="field.class" :style="field.style" type="password" :disabled="state.disabled" v-model="data[field.name]"
                              v-on:input="$emit('input')"/>
            </b-input-group>
        </template>
        <template v-else-if="field.type === 'RADIO'">
            <div class="input-group input-group-sm" :class="field.inputgroupclass" :style="field.inputgroupstyle">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{ field.label }}</span>
                    <div class="input-group-text">
                        <template v-for="(option,optionIndex) in field.options">
                            <div :key="field.name+'-'+optionIndex" class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" :id="field.name+'-'+optionIndex"
                                       :value="option.value" :disabled="state.readonly || state.disabled" v-model="data[field.name]"
                                       v-on:change="$emit('input')">
                                <label class="form-check-label" :for="field.name+'-'+optionIndex">{{ option.label }}</label>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="field.type === 'RICHTEXT'">
            <div>
                <div v-if="!field.compact && field.label" class="input-group-text" :class="field.labelclass" :style="field.labelstyle">{{ field.label }}</div>
                <div :class="field.class" :style="field.style">
                    <RichTextField :key="'field-'+field.id" :field="field" :data="data" :state="state"
                                   v-on:input="$emit('input')"/>
                </div>
            </div>
        </template>
        <template v-else-if="field.type === 'TEXT'">
            <b-input-group :key="'field-'+field.id" :prepend="label" :class="field.inputgroupclass" :style="field.inputgroupstyle" size="sm">
                <TextField :field="field" :data="data" :state="state" v-on:input="$emit('input')"/>
                <b-input-group-append v-if="field.append">
                    <b-input-group-text>{{ field.append }}</b-input-group-text>
                </b-input-group-append>
            </b-input-group>
        </template>
        <template v-else-if="field.type === 'TEXTAREA'">
            <b-input-group :key="'field-'+field.id" :prepend="label" :class="field.inputgroupclass" :style="field.inputgroupstyle" size="sm">
                <b-form-textarea :class="field.class" :style="field.style" :rows="field.rows" :max-rows="field.maxrows"
                                 :disabled="state.disabled" :autofocus="field.autofocus"
                                 v-model="data[field.name]"
                                 v-on:input="$emit('input')"/>
            </b-input-group>
        </template>
        <template v-else-if="field.type === 'TIME'">
            <b-input-group :key="'field-'+field.id" :prepend="label" :class="field.inputgroupclass" :style="field.inputgroupstyle" size="sm">
                <b-form-input :class="field.class" :style="field.style" type="time" :disabled="state.disabled" v-model="data[field.name]"
                              v-on:input="$emit('input')"/>

            </b-input-group>
        </template>
        <template v-else-if="field.type === 'TRANSLATABLE'">
            <TranslatableField :key="'field-'+field.id"
                               :field="field" :data="data" :state="state" :userLanguage="userLanguage" :languages="languages"
                               v-on:input="$emit('input')"/>
        </template>
        <template v-else>
            <b-input-group :key="'field-'+field.id" :prepend="label" :class="field.inputgroupclass" :style="field.inputgroupstyle" size="sm">
                <b-form-input :class="field.class" :style="field.style" type="text" :disabled="state.disabled" :state="false" :value="'Unknown field type ('+field.type+')'"/>
            </b-input-group>
        </template>
        <div v-if="formErrors && formErrors[field.name]" class="form-error">{{ formErrors[field.name][0] }}</div>
    </div>
</template>

<script>
import BoolField from "@/components/forms/fields/BoolField";
import EnumField from "@/components/forms/fields/EnumField";
import NumericField from "@/components/forms/fields/NumericField";
import RichTextField from "@/components/forms/fields/RichTextField";
import SelectField from "@/components/forms/fields/SelectField";
import TextField from "@/components/forms/fields/TextField";
import TranslatableField from "@/components/forms/fields/TranslatableField";

export default {
    name: "FormField",
    props: ['field', 'data', 'readonly', 'formErrors', 'userLanguage', 'languages'],
    components: {
        BoolField,
        EnumField,
        NumericField,
        RichTextField,
        SelectField,
        TextField,
        TranslatableField,
    },
    data() {
        return {
            disableUpdateRelationButton: !this.data[this.field.name],
            state: {
                readonly: this.readonly,
                disabled: !this.field.editinform || this.readonly,
            }
        }
    },
    computed: {
        label: function () {
            if (this.field.compact) {
                return null;
            } else {
                if (!this.state.readonly && this.field.editinform && this.field.required && this.field.type !== 'BOOL') {
                    return this.field.label + ' *';
                } else {
                    return this.field.label;
                }
            }
        },
    },
    methods: {
        onInputRelation() {
            this.disableUpdateRelationButton = !this.data[this.field.name];
            this.$emit('input');
        },
    }
}
</script>

<style scoped>
.form-error {
    color: red;
    text-align: center;
    margin-top: 5px;
}
</style>