<template>
    <b-input-group :prepend="label" :class="groupClass" :style="field.inputgroupstyle" size="sm">
        <b-form-input :key="'field-'+field.id" :autofocus="field.autofocus"
                      :class="field.class" :style="field.style" type="text" :disabled="state.disabled"
                      v-model="translation"
                      v-on:input="onInput()"/>
        <b-input-group-append>
            <b-dropdown :id="'dropdown-'+field.id" :text="userLanguage" right variant="dark" size="sm">
                <b-dropdown-form>
                    <template v-for="language in languages">
                        <b-input-group :key="'lan' + language" style="width: 440px">
                            <b-form-input :key="'field-'+field.id+'-'+language" type="text" v-model="data[field.name][language]" :disabled="state.disabled"
                                          v-on:input="onInputTranslations(language, data[field.name][language])"/>
                            <template v-slot:append>
                                <b-input-group-text style="width: 40px;">{{ language }}</b-input-group-text>
                            </template>
                        </b-input-group>
                    </template>
                </b-dropdown-form>
            </b-dropdown>
        </b-input-group-append>
    </b-input-group>
</template>

<script>
export default {
    name: "TranslatableField",
    props: ["field", "data", "state", 'userLanguage', 'languages'],
    data() {
        return {
            groupClass: this.field.inputgroupclass ? this.field.inputgroupclass + ' no-gutters' : 'no-gutters',
            translation: this.data[this.field.name][this.userLanguage],
        }
    },
    computed: {
        label: function () {
            if (!this.state.readonly && this.field.editinform && this.field.required) {
                return this.field.label + ' *';
            } else {
                return this.field.label;
            }
        }
    },
    methods: {
        onInput() {
            this.data[this.field.name][this.userLanguage] = this.translation;
            this.$emit('input');
        },
        onInputTranslations(language, translation) {
            if (this.userLanguage === language) {
                this.translation = translation;
            }
            this.$emit('input');
        }
    }
}
</script>

<style scoped>
</style>